const User_Token_Var = 'thr_user_token_var'
const User_Role_Var = 'thr_user_role_var'
const User_Refresh_Token_Var = 'thr_user_role_var'

const UserProfile = (function () {
  var getToken = function () {
    return localStorage.getItem(User_Token_Var)
  }

  var setToken = function (token) {
    localStorage.setItem(User_Token_Var, token)
  }

  var getRole = function () {
    return localStorage.getItem(User_Role_Var)
  }

  var getRefreshToken = function () {
    return localStorage.getItem(User_Refresh_Token_Var)
  }

  var setRole = function (token) {
    localStorage.setItem(User_Role_Var, token)
  }

  var setRefreshToken = function (token) {
    localStorage.setItem(User_Refresh_Token_Var, token)
  }

  var logout = function () {
    localStorage.setItem(User_Refresh_Token_Var, undefined)
    localStorage.setItem(User_Role_Var, undefined)
    localStorage.setItem(User_Token_Var, undefined)
  }

  return {
    getToken: getToken,
    setToken: setToken,
    getRole: getRole,
    setRole: setRole,
    setRefreshToken: setRefreshToken,
    getRefreshToken: getRefreshToken,
    logout: logout,
  }
})()

export default UserProfile
