import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import './assets/scss/style.scss'
import UserProfile from './storage/UserSessionStorage'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const CustomersList = React.lazy(() => import('./views/pages/customersList/CustomersList'))
const ReportList = React.lazy(() => import('./views/pages/reports/ReportList'))
const AddUser = React.lazy(() => import('./views/pages/addUser/AddUser'))
const Profile = React.lazy(() => import('./views/pages/profile/Profile'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Navigate to="/login" replace />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              element={<ForgotPassword />}
            />
            <Route
              exact
              path="/reset-password/:token"
              name="Reset Password Page"
              element={<ResetPassword />}
            />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/customers" name="Customers" element={<CustomersList />} />
            <Route exact path="/adduser" name="Add User" element={<AddUser />} />
            <Route exact path="/profile" name="Profile" element={<Profile />} />
            <Route exact path="/reports" name="Profile" element={<ReportList />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
